
//本地引入
import i18n from "@/lang";
import { getPwdErrMsg } from "@/utils";
import { UserModule } from "@/store/modules/user";
import { isValidPassword } from "@/utils/validate";
import { changePassword } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";

//公共库
import { Form, Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "ResetPassword",
  components: {
    Pagination,
  },
})

//组件函数
export default class extends Vue {
  //定义变量
  private listQuery = {
    password: "", // 旧密码
    newpassword: "", // 新密码
    nextpassword: "", // 确认密码
  };

  //创建时调用
  created() {
    //重置
    this.handleReset();
  }

  //登出账号
  private async logOut() {
    //登出账号
    await UserModule.logOut();

    //显示登录界面
    this.$router.push(`/login?redirect=${this.$route.fullPath}`).catch((err) => {
      console.warn(err);
    });
  }

  //处理保存
  private handleSave(): void {
    //验证表单数据
    (this.$refs.form as Form).validate(async (valid) => {
      //成功
      if (valid) {
        //请求参数
        const reqParams = {
          password: this.listQuery.password.trim(),
          newpassword: this.listQuery.newpassword.trim(),
          nextpassword: this.listQuery.nextpassword.trim(),
        };

        //重置密码
        await changePassword(reqParams);

        //显示提示
        Message({
          type: "success",
          duration: 3 * 1000,
          message: "密码修改成功，请重新登录",
        });

        //推出重登
        this.$nextTick(() => {
          // 退出重重登
          this.logOut();
        });
      }
    });
  }

  //处理重置
  private handleReset(): void {
    //数据清空
    this.listQuery.password = "";
    this.listQuery.newpassword = "";
    this.listQuery.nextpassword = "";

    //清楚校验消息
    this.$nextTick(() => {
      // 移除校验结果
      (this.$refs.form as Form).clearValidate();
    });
  }

  //确认密码验证
  private equalToPassword = (rule: any, value: string, callback: Function) => {
    //验证失败
    if (this.listQuery.newpassword !== this.listQuery.nextpassword) {
      callback(new Error(i18n.t("profile.newPasswordError") as string));
    }
    //成功
    else {
      callback();
    }
  };

  //密码验证
  private validatePassword = (rule: any, value: string, callback: Function) => {
    //验证密码
    const vType = isValidPassword(this.listQuery.password);

    //验证失败
    if (vType !== 1) {
      const msg = getPwdErrMsg(vType);
      callback(new Error(msg as any));
    }
    //成功
    else {
      callback();
    }
  };

  //新密码验证
  private validateNewPassword = (rule: any, value: string, callback: Function) => {
    //验证密码
    const vType = isValidPassword(this.listQuery.newpassword);

    //验证失败
    if (vType !== 1) {
      const msg = getPwdErrMsg(vType);
      callback(new Error(msg as any));
    }
    //成功
    else {
      callback();
    }
  };

  //规则
  private rules = {
    u_password: [{ required: true, validator: this.validatePassword, trigger: "blur" }],
    u_nextpassword: [{ required: true, validator: this.equalToPassword, trigger: "blur" }],
    u_newpassword: [{ required: true, validator: this.validateNewPassword, trigger: "blur" }],
  };
}
