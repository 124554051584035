
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import UserCard from "./components/user-card.vue";
import { UserModule } from "@/store/modules/user";
import ResetPassword from "./components/reset-password.vue";

//用户数据结构
export interface IUser {
  name: string;
  roles: string;
  email: string;
  avatar: string;
}

//用户默认数据
const defaultUser: IUser = {
  name: "Loading...",
  roles: "Loading...",
  email: "Loading...",
  avatar: "Loading...",
};

//组件
@Component({
  name: "Profile",
  components: {
    UserCard,
    ResetPassword,
  },
})

//组件函数
export default class extends Vue {
  //定义变量
  private user: IUser = defaultUser; //用户

  //创建时调用
  created() {
    //获取用户
    this.getUser();
  }

  //名称
  get name(): string {
    return UserModule.name;
  }

  //邮箱
  get email(): string {
    return UserModule.email;
  }

  //头像
  get avatar(): string {
    return UserModule.avatar;
  }

  //角色
  get roles(): string[] {
    return UserModule.roles;
  }
  //获取用户
  private getUser(): void {
    //数据赋值
    this.user = {
      name: this.name,
      email: this.email,
      avatar: this.avatar,
      roles: this.roles.join(" | "),
    };
  }
}
