
//本地引入
import { IUser } from "../index.vue";
import PanThumb from "@/comp/PanThumb/index.vue";

//公共库
import { Component, Prop, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "UserCard",
  components: {
    PanThumb,
  },
})

//组件函数
export default class extends Vue {
  //组件参数
  @Prop({ required: true }) private user!: IUser;
}
