
//公共库
import { Component, Prop, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "PanThumb",
})

//界面函数
export default class extends Vue {
  //组件参数
  @Prop({ default: 1 }) private zIndex!: number;
  @Prop({ required: true }) private image!: string;
  @Prop({ default: "150px" }) private width!: string;
  @Prop({ default: "150px" }) private height!: string;
}
